import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import {
  EXHIBITOR_ACTION_BUTTON_MESSAGE_FRAGMENT,
} from '@/graphql/_Fragments/Exhibitor/ActionButtons/Message';

export const DEAL_ACTION_BUTTON_MESSAGE_FRAGMENT = `
  fragment dealActionButtonMessageFragment on Deal {
    uid
    name
    title
    displayFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      ...exhibitorActionButtonMessageFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${EXHIBITOR_ACTION_BUTTON_MESSAGE_FRAGMENT}
`;
